import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import React, { useRef, useState, useEffect } from "react";
import * as data from "./locales/default.json";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import WarningIcon from "@material-ui/icons/Warning";
import apiConfig from "../utilities/Constants";
import axios from "axios";
import { toast } from "react-toastify";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getCurrentLanguage } from "../features/config/configSlice";
import { useSelector } from "react-redux";

const allowedExtensions = ["pdf", "jpg", "jpeg", "png"];

const useStyles = makeStyles({
  pdfTitle: {
    margin: "8px",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  pdfContainer: {
    textAlign: "center",
    position: "relative",
  },
  deleteButton: {
    position: "absolute",
    bottom: 13,
    left: 0,
    right: 2,
    margin: "0 8px",
    borderRadius: 0,
    color: "#fff",
  },
  dialog: {
    "& .MuiPaper-root": {
      background: "#cdd5e3",
    },
  },
  warningText: {
    color: "#ff9800",
    marginTop: "10px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
  warningIcon: {
    marginRight: "8px",
  },
  successText: {
    color: "#4caf50",
    marginTop: "10px",
  },
});

const UploadInvoice = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const inputRef = useRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [duplicateFiles, setDuplicateFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const language = useSelector(getCurrentLanguage);

  const checkForDuplicates = async (files) => {
    try {
      const fileNames = files.map(file => file.name);
      const response = await axios.post(`${apiConfig.BASE_API}/pdfs/check-duplicates`, { files: fileNames });
      return response.data.duplicates || [];
    } catch (error) {
      console.error("Error checking for duplicates:", error);
      return [];
    }
  };

  const handleFileChange = async (event) => {
    const filesToAdd = Array.from(event.target.files).filter((file) => {
      const extension = file.name.split(".").pop();
      return allowedExtensions.includes(extension.toLowerCase());
    });

    const newDuplicates = await checkForDuplicates(filesToAdd);
    setDuplicateFiles(newDuplicates);

    const allFiles = [...selectedFiles, ...filesToAdd.filter(file => !newDuplicates.includes(file.name))];
    setSelectedFiles(allFiles);
    if (allFiles.length > 0 || newDuplicates.length > 0) {
      setOpen(true);
    }
  };

  const handleListItemClick = () => {
    inputRef?.current?.click();
  };

  const handleUpload = async () => {
    if (selectedFiles.length == 0 || loading) return;
    setLoading(true);

    // Check for duplicates again before uploading
    const newDuplicates = await checkForDuplicates(selectedFiles);
    if (newDuplicates.length > 0) {
      setDuplicateFiles(prevDuplicates => [...new Set([...prevDuplicates, ...newDuplicates])]);
      setSelectedFiles(prevFiles => prevFiles.filter(file => !newDuplicates.includes(file.name)));
      setLoading(false);
      toast.warning(data.default[language].duplicateFilesWarning);
      return;
    }

    const formData = new FormData();
    selectedFiles.forEach((file, index) => {
      formData.append(`files`, file);
    });
    try {
      const response = await axios.post(
        `${apiConfig.BASE_API}/pdfs/uploads`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLoading(false);
      if (response.status == 200) {
        const { uploadedFiles, duplicates } = response.data;
        setUploadedFiles(uploadedFiles || []);
        setDuplicateFiles(prevDuplicates => [...new Set([...prevDuplicates, ...(duplicates || [])])]);
        if (uploadedFiles && uploadedFiles.length > 0) {
          toast.success(data.default[language].fileSuccessfulyUploaded);
        }
        if (duplicates && duplicates.length > 0) {
          toast.warning(data.default[language].duplicateFilesWarning);
        }
        if (uploadedFiles.length === 0 && (!duplicates || duplicates.length === 0)) {
          toast.error(data.default[language].uploadFailed);
        }
      } else {
        toast.error(data.default[language].uploadFailed);
      }
    } catch (error) {
      toast.error(data.default[language].uploadFailed);
      console.error("Upload error:", error);
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    inputRef.current.value = null;
    setOpen(false);
    setDuplicateFiles([]);
    setUploadedFiles([]);
    setTimeout(() => {
      setSelectedFiles([]);
    }, 300);
  };

  const getGridSize = () => {
    const gridSizeMap = {
      1: 12,
      2: 6,
      3: 4,
    };

    const selectedFilesLength = selectedFiles?.length || 0;
    return gridSizeMap[selectedFilesLength] || 4;
  };

  const deleteFileByIndex = (indexToDelete) => {
    const updateFiles = [
      ...selectedFiles.slice(0, indexToDelete),
      ...selectedFiles.slice(indexToDelete + 1),
    ];
    setSelectedFiles(updateFiles);
    if (updateFiles.length === 0 && duplicateFiles.length === 0) {
      setOpen(false);
    }
  };

  return (
    <>
      <ListItem
        className={props.classes.listItem}
        onClick={() => handleListItemClick()}
      >
        <ListItemAvatar>
          <DriveFolderUploadIcon style={{ color: "#64ffda", fontSize: 36 }} />
        </ListItemAvatar>
        <ListItemText
          classes={{ primary: props.classes.ListItemText }}
          primary={data.default[language].uploadInvoice}
        />
        <input
          ref={inputRef}
          id="fileInput"
          type="file"
          accept=".pdf,.jpg,.jpeg,.png"
          multiple={true}
          onChange={handleFileChange}
          onClick={null}
          style={{ display: "none" }}
        />
      </ListItem>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
      >
        {loading && <LinearProgress />}
        <DialogTitle id="alert-dialog-title">
          {data.default[language].uploadFileTitle}
        </DialogTitle>
        <DialogContent>
          {duplicateFiles.length > 0 && (
            <div className={classes.warningText}>
              <WarningIcon className={classes.warningIcon} />
              {data.default[language].duplicateFilesWarning}:
              <ul>
                {duplicateFiles.map((file, index) => (
                  <li key={index}>{file}</li>
                ))}
              </ul>
            </div>
          )}
          {selectedFiles.length > 0 && (
            <Grid container spacing={2}>
              {selectedFiles.map((file, index) => (
                <Grid
                  className={classes.pdfContainer}
                  item
                  wrap="wrap"
                  xs={getGridSize()}
                  key={index}
                >
                  <p className={classes.pdfTitle}>{file.name}</p>
                  {file.name?.toLowerCase()?.endsWith(".pdf") ? (
                    <iframe
                      title={`PDF Viewer ${index}`}
                      src={
                        URL.createObjectURL(file) +
                        "#navpanes=0&statusbar=0&toolbar=0&view=FitH"
                      }
                      width="100%"
                      height="250px"
                    />
                  ) : (
                    <div className="imagePreviewContainer small">
                      <img
                        className="imagePreview"
                        src={URL.createObjectURL(file)}
                        alt={`Preview of ${file.name}`}
                      />
                    </div>
                  )}
                  {selectedFiles.length > 1 && (
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.deleteButton}
                      onClick={() => deleteFileByIndex(index)}
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  )}
                </Grid>
              ))}
            </Grid>
          )}
          {uploadedFiles.length > 0 && (
            <div className={classes.successText}>
              {data.default[language].fileSuccessfulyUploaded}:
              <ul>
                {uploadedFiles.map((file, index) => (
                  <li key={index}>{file}</li>
                ))}
              </ul>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container justify="space-between">
            <Grid item>
              <Button onClick={() => handleListItemClick()} color="primary">
                {data.default[language].addMore}
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={() => handleCloseModal()} color="primary">
                {data.default[language].cancel}
              </Button>
              <Button onClick={() => handleUpload()} color="primary" autoFocus disabled={selectedFiles.length === 0}>
                {data.default[language].send}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadInvoice;
